<!--
  功能：导出
  时间：2024年09月26日 15:50:51
  版本：v1.0
-->
<template>
  <el-button
    :type="btnType"
    :icon="icon"
    @click="f_export()"
    >{{ btnText }}</el-button
  >
</template>

<script>
export default {
  name: 'downLoad',
  // 组件参数 接收来自父组件的数据
  props: {
    btnText: {
      type: String,
      default: '导出', // 默认文本
    },
    btnType: {
      type: String,
      default: 'primary', // 默认类型
    },
    icon: {
      type: String,
      default: 'el-icon-download', // 默认图标
    },
    url: {
      type: String,
      default: '',
    },
    API: {
      type: Function,
      default: async () => {
        return Promise.resolve();
      },
    },
    params: {
      type: Object,
      default: () => {
        return {};
      },
    },
    fileName: {
      type: String,
      default: '',
    },
  },
  // 组件状态值
  data() {
    return {};
  },
  // 组件方法
  methods: {
    f_export() {
      this.$emit('export');
      if (this.url) {
        window.open(this.url, '_blank');
      } else {
        this.$showLoading();
        this.API(this.params)
          .then(res => {
            if (!res.data) return;
            // const fileName = `BC消耗汇总${new Date().getTime()}.xlsx`;
            const blob = new Blob([res.data]);
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.style.display = 'none';
            link.setAttribute('href', url);
            link.setAttribute('download', this.fileName);
            link.click();
            URL.revokeObjectURL(url);
          })
          .finally(() => {
            this.$hideLoading();
          });
      }
    },
  },
};
</script>
