<template>
  <div class="tablePage h-full flex flex-col">
    <div class="tableForm">
      <ad-form
        :filedList="filedList"
        v-model="formData"
        :formPrams="formPrams"
        @change="change"
        ref="formRef"
        v-if="showForm"
        @submit="search"
        @reset="reset"
        @input="(val) => $emit('input', val)"
      >
        <template v-slot:formCon="data">
          <slot :name="data.data.name"></slot>
        </template>
      </ad-form>
    </div>
    <div class="operate">
      <!-- <slot name="middle"></slot> -->
      <div>
        <span
          v-for="(btn, index) in btnList"
          :key="index"
        >
          <el-dropdown
            size="small"
            v-if="btn.type == 'dropdown'"
            trigger="click"
          >
            <el-button
              size="small"
              :type="btn.btnType"
              :disabled="btn.disabled"
            >
              {{ btn.text }}<el-icon class="el-icon-arrow-down"></el-icon>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="(dropItem, ind) in btn.dropDownList"
                  @click="dropItem.click"
                  :disabled="dropItem.disabled"
                  >{{ dropItem.text }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <el-tooltip
            :content="btn.tooltip?.content"
            :placement="btn.tooltip?.placement"
            :disabled="!btn.tooltip"
            v-else
          >
            <el-button
              :type="btn.btnType"
              size="small"
              @click="btn.click"
              :disabled="btn.disabled"
              :icon="btn.icon"
              >{{ btn.text }}</el-button
            >
          </el-tooltip>
        </span>
      </div>
    </div>
    <div class="tableWrap flex-1 flex flex-col h-0 overflow-hidden">
      <ad-table
        @selectChange="selectChange"
        ref="tableRef"
        :tableConfig="{ ...tableConfig, formData: formData }"
      >
        <template v-slot:content="{ row, item }">
          <slot
            :name="item.prop"
            :row="row"
          >
            <div :style="item.style">
              <span v-if="item.formatter">
                {{ item.formatter(row) }}
              </span>
              <span v-else>{{ row[item.prop] || '--' }}</span>
            </div>
          </slot>
        </template>
      </ad-table>
    </div>
  </div>
</template>
<script>
import adTable from '../adTable/index';
import adForm from '../adForm/index';
export default {
  props: {
    filedList: {
      type: Object,
      default: () => {},
    },
    formPrams: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Object,
      default: () => {},
    },
    showForm: {
      type: Boolean,
      default: true,
    },
    tableConfig: {
      type: Object,
      default: () => {},
    },
    btnList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    adTable,
    adForm,
  },
  computed: {
    formData: {
      get() {
        return JSON.parse(JSON.stringify(this.value));
      },
      set(v) {
        // this.$emit('input', this.formData);
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    change(type, v) {
      this.$emit('change', { type, v });
    },
    selectChange(rows) {
      this.$emit('selectChange', rows);
    },
    reset() {
      this.$emit('reset');
      this.$nextTick(() => {
        this.search();
      });
    },
    search() {
      this.$refs.tableRef.getTableData();
    },
  },
};
</script>
<style lang="scss" scoped>
.operate {
  display: flex;
  align-items: center;
  justify-content: space-between;

  :deep(.el-button) {
    margin: 0 10px 10px 0;
  }
  :deep(.el-button-group .el-button) {
    margin-right: 0;
  }
}

.tablePage {
  // padding: 10px;

  .tableForm {
    :deep(.el-form-item) {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}
</style>
