<template>
  <div class="flex h-full flex-col">
    <div
      :loading="loading"
      class="table-wrapper overflow-auto flex-1 border border-[#eeeeee]"
    >
      <el-table
        :data="tableData"
        :max-height="tableConfig.maxHeight"
        :default-sort="tableConfig.defaultSort"
        @selection-change="handleSelectionChange"
        @sort-change="handleSortChange"
        @row-click="goDetail"
        :row-style="{ cursor: 'pointer' }"
        table-layout="auto"
        ref="tableRef"
        stripe
        style="height: 100%"
        v-loading="loading"
        :row-key="tableConfig.rowKey"
      >
        <el-table-column
          fixed
          :selectable="_selectable"
          type="selection"
          :reserve-selection="true"
          v-if="tableConfig.showSelectBox"
        />
        <el-table-column
          v-for="item in tableConfig.columns"
          :key="item.prop"
          :prop="item.prop"
          :label="item.label"
          :sortable="item.sortable ? 'custom' : false"
          :width="item.width"
          :min-width="item.minWidth"
          :type="item.type"
        >
          <template #header>
            <slot name="header">
              <div
                class="inline-flex"
                :style="item.labelStyle"
              >
                <span style="margin-right: 5px">{{ item.label }}</span>
                <el-tooltip
                  popper-class="table-tooltip"
                  effect="dark"
                  placement="top-start"
                  :content="item.tooltip"
                  v-if="item.tooltip"
                >
                  <el-icon class="el-icon-info"></el-icon>
                </el-tooltip>
              </div>
            </slot>
          </template>
          <template
            #default="scope"
            v-if="!item.type"
          >
            <div
              @click="item.click?.(scope.row)"
              :class="{ 'text-sky-500': item.click }"
            >
              <slot
                name="content"
                :item="item"
                :row="scope.row"
              >
                <slot
                  :name="item.prop"
                  :row="scope.row"
                >
                  <span v-if="item.formatter">
                    {{ item.formatter(scope.row) }}
                  </span>
                  <span v-else>{{ scope.row[item.prop] ? scope.row[item.prop] : '--' }}</span>
                </slot>
              </slot>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          :width="tableConfig.operation.width"
          v-if="tableConfig.operation && tableConfig.operation.columns"
        >
          <template #default="scope">
            <slot
              name="operations"
              :row="scope.row"
            >
              <span
                v-for="item in tableConfig.operation.columns"
                :key="item.text || item.icon"
              >
                <el-button
                  v-if="setVisible(scope.row, item.visible)"
                  :type="item.type"
                  :link="item.link"
                  :plain="item.plain"
                  @click="item.click(scope.row)"
                  size="small"
                  style="padding: 4px"
                  class="mr-1"
                >
                  <el-icon
                    v-if="item.icon"
                    :class="item.icon"
                  ></el-icon>
                  {{ item.text }}
                </el-button>
              </span>
            </slot>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      :total="total"
      :layout="tableConfig.layout"
      :current-page="pagination.pageNum"
      v-if="tableConfig.showPage"
      :page-size="pagination.pageSize"
      :page-sizes="tableConfig.pageSizes"
      @current-change="currentPageChange"
      @size-change="sizeChange"
      class="py-2 flex justify-end mt-2"
    />
  </div>
</template>

<script>
const initPage = { pageNum: 1, pageSize: 20 };
export default {
  props: {
    tableConfig: {
      type: Object,
      default: () => {
        return {
          rowKey: 'id',
          layout: 'sizes,prev, pager, next, total',
          pageSizes: [10, 20, 50, 100],
          showPage: true,
          stripe: true,
          selectable: () => true,
          showSelectBox: true,
          immediately: true,
          formData: () => ({}),
        };
      },
    },
  },
  data() {
    return {
      sort: {
        prop: '',
        order: '',
      },
      tableData: [],
      total: 0,
      selectionRows: [],
      showOperation: false,
      loading: false,
      pagination: { ...initPage },
    };
  },
  computed: {
    _selectable() {
      if (typeof this.tableConfig.selectable == 'function') {
        return this.tableConfig.selectable;
      }
      return () => {
        return this.tableConfig.selectable;
      };
    },
    dataList() {
      return this.tableConfig.tableData;
    },
    refresh() {
      return this.tableConfig.refresh;
    },
  },
  watch: {
    dataList: {
      handler() {
        this.tableData = this.tableConfig.tableData;
      },
      deep: true,
    },
    refresh: {
      handler() {
        this.reLoadTable();
      },
    },
  },
  mounted() {
    if (this.tableConfig.immediately) {
      this.reLoadTable();
    }
  },
  methods: {
    handleSelectionChange(rows) {
      this.selectionRows = rows;
      this.isSelected = rows.length > 0;
      this.$emit('selectChange', rows);
    },
    setVisible(row, visible) {
      if (!visible || visible(row)) {
        this.showOperation = true;
        return true;
      }
      return false;
    },
    handleSortChange({ column, prop, order }) {
      this.sort = { prop, order };
      if (column.sortable == 'custom') {
        this.reLoadTable();
      }
    },
    getTableData() {
      let params = this.tableConfig.formatterParams
        ? this.tableConfig.formatterParams({
            ...this.pagination,
            ...this.sort,
            ...this.tableConfig.formData,
          })
        : { ...this.pagination, ...this.sort, ...this.tableConfig.formData };

      if (!this.tableConfig.api) {
        if (this.tableConfig.tableData) {
          alert('');
          this.tableData = this.tableConfig.tableData;
          this.total = this.tableConfig.total;
        }
        return;
      }
      if (typeof this.tableConfig.api == 'string') {
        this.tableData = [];
        return;
      }
      this.loading = true;

      this.tableConfig
        .api(params)
        .then((res) => {
          let response = this.tableConfig.formatterTableData ? this.tableConfig.formatterTableData(res) : res.data.list;
          this.loading = false;
          this.showOperation = false;
          this.tableData = response.list;
          this.total = response.total;
          // 获取表格复杂数据
          nextTick(() => {
            this.tableConfig.getTableColumnsData?.(this.tableData.map((v) => v[this.tableConfig.rowKey]));
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    currentPageChange(v) {
      this.pagination.pageNum = v;
      this.getTableData();
    },
    sizeChange(v) {
      this.pagination.pageNum = 1;
      this.pagination.pageSize = v;
      this.getTableData();
    },
    reLoadTable() {
      this.pagination = { ...initPage };
      this.getTableData();
    },
    goDetail(row) {},
  },
};
</script>
;

<style lang="scss" scoped>
// .table-wrapper {
//   border-top: 1px solid #eaeaea;
//   border-left: 1px solid #eaeaea;
//   border-right: 1px solid #eaeaea;
// }

.inline-flex {
  display: inline-flex;
  align-items: center;
}

.p-14 {
  border-bottom: 1px solid #eaeaea;
  padding: 14px;
}

// .p-y-20 {
//   padding-top: 20px;
//   padding-bottom: 20px;
//   justify-content: flex-end;
// }
</style>

<style lang="scss" scoped>
.table-tooltip {
  max-width: 220px;
}
</style>
