<template>
  <div>
    <el-form
      ref="formDataRef"
      v-model="formData"
      v-bind="formPrams"
      status-icon
      onsubmit="return false"
    >
      <el-form-item
        :label="item.label"
        :prop="item.key"
        v-for="item of filedList"
        :key="item.key"
      >
        <div class="w-full">
          <template v-if="item.type != 'slot'">
            <template v-if="item.type == 'select'">
              <el-select
                v-model="formData[item.key]"
                :placeholder="item.placeholder || `请选择${item.label}`"
                style="min-width: 120px"
                v-bind="item.selectOptions"
                @change="val => changeEvent(item.change, val)"
                clearable
              >
                <el-option
                  v-for="(opt, ind) in item.options"
                  :label="
                    opt[
                      item.optionsConfig && item.optionsConfig.label
                        ? item.optionsConfig.label
                        : 'name'
                    ]
                  "
                  :value="
                    opt[
                      item.optionsConfig && item.optionsConfig.value
                        ? item.optionsConfig.value
                        : 'id'
                    ]
                  "
                  :key="ind"
                >
                  <slot
                    name="isSelfDefineCenter"
                    :data="opt"
                  >
                    <slot
                      :name="item.key"
                      :data="opt"
                    ></slot>
                  </slot>
                </el-option>
              </el-select>
            </template>
            <el-checkbox-group
              v-model="formData[item.key]"
              v-else-if="item.type == 'checkbox'"
              @change="val => changeEvent(item.change, val)"
            >
              <el-checkbox
                v-for="(opt, ind) in item.options"
                :label="opt.id"
                :key="ind"
                >{{ opt.name }}</el-checkbox
              >
            </el-checkbox-group>
            <el-radio-group
              v-model="formData[item.key]"
              v-else-if="item.type == 'radio'"
              @change="val => changeEvent(item.change, val)"
            >
              <el-radio
                :label="opt.id"
                v-for="(opt, ind) in item.options"
                :key="ind"
                >{{ opt.name }}</el-radio
              >
            </el-radio-group>
            <el-switch
              v-model="formData[item.key]"
              v-else-if="item.type == 'switch'"
            />
            <el-date-picker
              v-model="formData[item.key]"
              type="datetimerange"
              :start-placeholder="item.text && item.text[0] ? item.text[0] : '开始时间'"
              :end-placeholder="item.text && item.text[1] ? item.text[1] : '结束时间'"
              :range-separator="item.separator || '至'"
              v-else-if="item.type == 'dateRange'"
              v-bind="item.config"
              @input="val => onChangeDatePicker(val, item)"
            />
            <el-input-number
              v-model="formData[item.key]"
              v-else-if="item.type == 'number'"
              v-bind="item.config"
              @change="val => changeEvent(item.change, val)"
            ></el-input-number>
            <el-input
              v-model="formData[item.key]"
              :placeholder="item.placeholder || `请输入${item.label}`"
              type="textarea"
              v-else-if="item.type == 'textarea'"
              v-bind="item.config"
              v-on="item.configOn"
              @change="val => changeEvent(item.change, val)"
              clearable
            />
            <template v-else-if="item.type == 'btn'">
              <el-button
                type="primary"
                @click="submitForm"
                >查询</el-button
              >
              <el-button @click="resetForm">重置</el-button>
            </template>
            <el-input
              v-model="formData[item.key]"
              :placeholder="item.placeholder || `请输入${item.label}`"
              @input="val => changeEvent(item.change, val)"
              v-else
            />
          </template>
          <template v-else>
            <slot
              name="formCon"
              :data="{ item: item, name: item.slotName }"
            >
              <slot :name="item.slotName"></slot>
            </slot>
          </template>
          <div
            v-if="item.tip"
            class="text-xs text-slate-400 my-1"
          >
            {{ item.tip }}
          </div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    filedList: {
      type: Object,
      default: () => {},
    },
    formPrams: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    formData: {
      get() {
        return JSON.parse(JSON.stringify(this.value));
      },
      set(v) {
        // this.$emit('input', this.formData);
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    // 重置
    resetForm() {
      this.$refs.formDataRef.resetFields();
      this.$emit('reset');
    },
    // 验证表单
    validateForm() {
      if (!this.$refs.formDataRef) return;
      return this.$refs.formDataRef.validate();
    },
    // 查询
    submitForm() {
      this.$emit('submit', this.formData);
    },
    changeEvent(key, value) {
      this.$emit('input', JSON.parse(JSON.stringify(this.formData)));
      this.$emit('change', key, value);
    },
    // 时间选择
    onChangeDatePicker(val, item) {
      console.log(val, item);
      let formData = this.formData;
      if (item.startKey) {
        formData[item.startKey] = val ? val[0] : null;
      }
      if (item.endKey) {
        formData[item.endKey] = val ? val[1] : null;
      }
      this.changeEvent(item.change, val);
    },
  },
};
</script>
<style lang="scss" scoped></style>
